body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat', sans-serif;
  overflow-y: hidden;
  overflow-x: hidden;
}

body:before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: url('https://res.cloudinary.com/novom-networks/image/upload/q_auto/v1571252672/skyshow/benchmarks/images/app/generalBackground2.jpg') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

button:focus {
  outline: none;
}

p {
  margin: 0;
  line-height: 1.3;
}

a {
  text-decoration: underline;
}
